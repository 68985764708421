
import EnLang from 'assets/Images/En.png'
import achievements from 'assets/Images/achievements.png'
import ArLang from 'assets/Images/ar.png'
import banner_about from 'assets/Images/banner_about.png'
import banner_services from 'assets/Images/banner_services.png'
import Clients from 'assets/Images/clients.png'
import header from 'assets/Images/header.png'
import Logo from 'assets/Images/logo.png'
import LogoBg from 'assets/Images/logo.png'
import LogoSm from 'assets/Images/logo_sm.png'


const img = { 
    Logo,
    header,
    Clients,
    ArLang,
    EnLang,
    banner_about,
    banner_services,
    achievements,
    LogoBg,
    LogoSm,  
 
}

export default img;
